<template>
  <VUploadFile />
</template>

<script>
import VUploadFile from "@/components/inputs/VUploadFile";

export default {
  components: {
    VUploadFile
  }
};
</script>
